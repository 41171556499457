@import '../partials';

.image-callout {
  $c: &;

  @include section-padding;

  &--pattern {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;

    @include bp(lg){
      background-image: url('../DocumentDefault/Assets/beam.svg');
    }
  }

  &--align-right {

    #{$c}__boundary {
      flex-direction: row-reverse;
    }

    #{$c}__text {
      @include bp(lg){
        margin-right: rem(40);
      }
    }
  }

  &--align-left {

    #{$c}__text {
      @include bp(lg){
        margin-left: rem(40);
      }
    }
  }

  &__boundary {
    @include content-contaner;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;

    @include bp(lg){
      flex-wrap: nowrap;
    }
  }

  &__image {
    min-width: 50%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 60rem;

    @include bp(lg){
      justify-content: flex-end;
    }

    img {
      align-self: center;
      width: 100%;
    }
  }

  &__text {
    max-width: rem(600);
    margin-top: rem(35);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @include bp(lg){
      margin-top: 0;
    }

    &-title {
      font-size: rem(22);
    }

    &-subtitle {
      margin-top: rem(4);
      font-size: rem(12);
      font-style: italic;
    }

    &-body {
      margin-top: rem(20);
      font-size: rem(18);
      line-height: rem(32);

      @include bp(lg){
        //margin-top: 0;
      }
    }

    &-link {
      margin-top: rem(25);
    }
  }
}